import { TrackViewEvent } from '@/components/common/EventClient/TrackViewEvent';
import { HorizontalScrollContainer } from '@/components/common/HorizontalScrollContainer';
import { a11y } from '@/components/styles/reset';
import { SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import type { SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';

import { useSectionTrackingDataContext } from '../../common/SectionTrackingDataContextProvider';
import * as styles from './QuickMenu.styles';
import { QuickMenuItem } from './QuickMenuItem';

export interface QuickMenuProps {
  section: SectionByLayout<SectionLayoutType.QuickMenu>;
}

export const QuickMenu = ({ section }: QuickMenuProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  return (
    <TrackViewEvent screenName={sectionTrackingData.screenName} target="section" params={sectionTrackingData.params}>
      <section css={styles.sectionStyle}>
        <span css={a11y}>퀵 메뉴</span>
        <HorizontalScrollContainer
          css={styles.containerStyle}
          arrowContainerCss={styles.arrowContainerStyle}
          eventParams={sectionTrackingData}
          leftArrowLabel="이전 퀵 메뉴"
          rightArrowLabel="다음 퀵 메뉴">
          <ul css={styles.listStyle}>
            {section.items.map(item => (
              <QuickMenuItem key={item.id} items={item} />
            ))}
          </ul>
        </HorizontalScrollContainer>
      </section>
    </TrackViewEvent>
  );
};
