import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

import * as sectionStyles from '../index.styles';

export const sectionStyle = css`
  ${sectionStyles.horizontalSectionStyle};
  margin-top: 30px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin-top: 16px;
    `,
  )};

  &:first-of-type {
    margin-top: 0;
  }
`;

export const arrowContainerStyle = sectionStyles.horizontalSectionArrowContainerStyle;
export const containerStyle = sectionStyles.horizontalSectionScrollContainerStyle;

export const listStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;

  ${sectionStyles.horizontalSectionScrollContainerListStyle};
`;

export const quickmenuItemStyle = css`
  display: inline-block;
  flex: none;
  margin: 0 10px;

  :first-of-type {
    margin-left: 0;
  }

  :last-of-type {
    margin-right: 0;
  }

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      margin: 0 5px;
    `,
  )};
`;

export const quickmenuAnchorStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: none;
  width: 70px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      width: 56px;
    `,
  )}
`;

export const quickmenuImageWrapperStyle = css`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
`;

export const quickmenuImageStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
`;

export const quickmenuLabelStyle = (theme: RIDITheme): SerializedStyles => css`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  word-break: keep-all;
  color: ${theme.colors.grey600};

  margin-top: 8px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 11px;
      line-height: 13px;
    `,
  )}
`;

export const quickMenuNewBadgeStyle = css`
  position: absolute;
  top: 0px;
  right: 0px;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      transform: scale(0.8);
      transform-origin: top right;
    `,
  )}
`;
